import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ListGroup from 'react-bootstrap/ListGroup'
import Phytolife from '../assets/Phytolife.png'
import Pro9 from '../assets/Pro9.png'
import Mystify from '../assets/Mystify.png'
import ThreeSystem from '../assets/ThreeSystem.png'
import E9 from '../assets/E9.png'
import COL from '../assets/COL.png'
import Button from 'react-bootstrap/Button'
import { AiOutlineCopyright } from 'react-icons/ai'
import EssentialG from '../assets/essentialGreens.png'
import Pro360 from '../assets/pro360.webp'
import CarbBlocker from '../assets/carbBlocker.png'
import SleepWell from '../assets/sleepWell.png'
import Beets from '../assets/beets.png'
import Liver from '../assets/liver.png'

const Shop = () => {
  return (
    <>
      <Container fluid style={{ display: 'flex', justifyContent: 'center', width: '100vw' }}>
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
            <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '110vw', position: 'relative' }}>
              <h6 style={{ margin: '0.25rem 0', fontSize: '4vh', textAlign: 'center', padding: '5vh 0 5vh' }}>Shop</h6>
            </div>
            <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '3rem' }}></div>
            {/* NEW PRODUCTS */}
            <div>
              <h6 className="guth6">PRO360 Superfood Performance Beets</h6>
              <ListGroup.Item className="gutHealthPara">
                {/* <i>Proargi-9+, Mistify, Phytolife</i> */}
                {/* <br />
                <br /> */}
                Better performance starts with better nutrition. Superfood Performance Beets, a unique and tasty combination of red beet&#39;s energizing strength plus two special blends of synergistic superfoods to help boost and sustain nitric oxide levels. This gives you greater vitality, recovery, mental acuity, performance, and healthy blood pressure. Nitric oxide offers a host of health benefits. It helps blood vessels relax so circulation improves. Increased circulation means more blood and nutrients get where they&#39;re needed. You feel it as greater energy, improved recovery, and focus. Nitric oxide is quickly oxidized in the body and must be replaced continually. And by the time you&#39;re 40 years old, your body only produces about HALF the levels it did when you were younger.
                <br />
                <br />
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/pro360-superfood-performance-beets">
              <img src={Beets} className="productImage" />
            </a>
            <ListGroup.Item className="gutHealthPara">
              <div>
                <p className="gutHealthParaBold">Key ingredients:</p>
                <ul>
                  <li>Beet root: Potent nitric oxide activator, sustainably sourced from Tibet.</li>
                  <li>Green Coffee Bean: Supports cardiovascular and cognitive health.</li>
                  <li>Turmeric: Supports a healthy cardiovascular response and immune health.</li>
                  <li>Apple: Helps reduce oxidation and boost nitric oxide to support heart health.</li>
                  <li>Green Tea Leaf: Supports circulatory health, contributes to enhance mental and physical capacities, and aids exercise recovery.</li>
                  <li>Grape Seed and Skin: Provides valuable blood vessel protection, reduces oxidative stress, helps maintain healthy blood pressure and blood flow.</li>
                  <li>Kale: Naturally contains heart-healthy nutrients.</li>
                  <li>Fructo-oligosaccharides (FOS): Provides food for probiotics in the gut and boosts gut health.</li>
                </ul>
                <br />
                <p className="gutHealthParaBold">Benefits:</p>
                <ul>
                  <li>Elevates natural energy production.</li>
                  <li>Promotes peak performance and vitality.</li>
                  <li>Supports exercise efficiency, capacity, and stamina.</li>
                  <li>Enhances blood flow by activating nitric oxide.</li>
                  <li>Helps maintain healthy blood pressure.</li>
                  <li>Aids focus, mood, and mental alertness.</li>
                  <li>Helps reduce fatigue.</li>
                  <li>Supports cardiovascular health and vessel tone.</li>
                  <li>Offers benefits for gut health and immune function.</li>
                </ul>
                <br />
                
              </div>
              <div style={{ textAlign: 'center', marginBottom: '0' }}>
                <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/pro360-superfood-performance-beets" className="btn-primary" >Shop Now</Button>
              </div>
            </ListGroup.Item>

            <br />
            <br />

            <div>
              <h6 className="guth6">PRO360 Wholefood Performance Greens</h6>
              <ListGroup.Item className="gutHealthPara">
                {/* <i>Proargi-9+, Mistify, Phytolife</i> */}
                {/* <br />
                <br /> */}
                Synergy’s delicious Pro360 Wholefood Performance Greens is packed with
superfoods that provide essential nutrients such as vitamins, minerals, fibre, and
antioxidants. It features a unique blend that address nutrition, energy, digestive
health, immune health, psychological function, and oxidation, while providing
nourishing whole foods and upcycled fruit fibre. With 23 vitamins and minerals, it
provides clinically effective levels of nutrients that help fill gaps in your diet.
                <br />
                <br />
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/pro360-wholefood-performance-greens">
              <img src={Pro360} className="productImage" />
            </a>
            <div style={{ textAlign: 'center', marginBottom: '0' }}>
              <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/pro360-wholefood-performance-greens" className="btn-primary" >Shop Now</Button>
            </div>

            <br />
            <br />

            <div>
              <h6 className="guth6">Liver Health Formula</h6>
              <ListGroup.Item className="gutHealthPara">
                {/* <i>Proargi-9+, Mistify, Phytolife</i> */}
                {/* <br />
                <br /> */}
                Your liver performs hundreds of vital functions for you, as a large filter, it removes toxins from the blood. Liver Health Formula contains herbs traditionally used to support and nourish the liver by providing key phytonutrients and support elimination channels in the body. A unique blend of tonifying herbs protect liver and aid its natural functions including toxin elimination, digestion, and antioxidant defence.
                <br />
                <br />
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/liver-health-formula">
              <img src={Liver} className="productImage" />
            </a>
            <ListGroup.Item className="gutHealthPara">
              <div>
                <p className="gutHealthParaBold">Key Ingredients:</p>
                <ul>
                  <li>Burdock root</li>
                  <li>Turmeric root</li>
                  <li>Gentian root</li>
                  <li>Spirulina</li>
                  <li>Dandelion</li>
                </ul>
                <br />
                Liver cleanse also features other herbs like slippery elm, fenugreek seed and safflower that naturally contain bioactive compounds to support the elimination channels. Traditionally, the inner bark of slippery elm, fenugreek seeds and safflower have been used to soothe and support gastrointestinal and urinary functions.
              </div>
              <div style={{ textAlign: 'center', marginBottom: '0' }}>
                <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/liver-health-formula" className="btn-primary" >Shop Now</Button>
              </div>
            </ListGroup.Item>

            <br />
            <br />

            <div>
              <h6 className="guth6">Carb Blocker</h6>
              <ListGroup.Item className="gutHealthPara">
                {/* <i>Proargi-9+, Mistify, Phytolife</i> */}
                {/* <br />
                <br /> */}
                Carb Blocker is an all-natural product containing 300 mg of white kidney beans in
each capsule that helps block enzymes responsible for digesting carbohydrates.
When consuming carbs, your body uses enzymes to break down the food to later
absorb it as sugars. White kidney beans intervene and inhibit those enzymes. Carb
Blocker will help you support a healthy lifestyle in conjunction with proper diet and
exercise.
                <br />
                <br />
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/carb-blocker">
              <img src={CarbBlocker} className="productImage" />
            </a>
            <div style={{ textAlign: 'center', marginBottom: '0' }}>
              <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/carb-blocker" className="btn-primary" >Shop Now</Button>
            </div>

            <br />
            <br />
            <div>
              <h6 className="guth6">Sleep Well</h6>
              <ListGroup.Item className="gutHealthPara">
                {/* <i>Heart Healthy, Energizing and Immunity boosting.</i> */}
                {/* <br />
                <br /> */}
                Sleep Well features Valerian Root extract, passionflower and hop flower.
Passionflower is known to have a calming and sleep-inducing effect and Valerian
has been traditionally used to support natural and healthy sleep. Valerian Root
extract is a well-known ingredient used to calm down and help us get the restorative
sleep and rest we need. It is known not to cause morning grogginess and it is a non-
addictive supplement for sleep support. With steady release of Valerian Root Extract,
Sleep Well prepares your body for a relaxed night&#39;s sleep without any sudden crash.
                <br />
                <br />
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/sleepwell">
              <img src={SleepWell} className="productImage" />
            </a>
            <ListGroup.Item className="gutHealthPara">
              <div>
                <p className="gutHealthParaBold">Benefits:</p>
                <ul>
                  <li>Supports nervous system health.</li>
                  <li>Helps maintain good cognitive function.</li>
                  <li>Helps induce a state of calm.</li>
                  <li>May aid in reducing repetitive and unintentional movements (psychomotor
agitation).</li>
                </ul>
                <br />
              </div>
              <div style={{ textAlign: 'center', marginBottom: '0' }}>
                <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/sleepwell" className="btn-primary" >Shop Now</Button>
              </div>
            </ListGroup.Item>

            <br/>
            <br/>

            <div>
              <h6 className="guth6">Proargi-9+</h6>
              <ListGroup.Item className="gutHealthPara">
                <i>Heart Healthy, Energizing and Immunity boosting.</i>
                <br />
                <br />
                Contains the Nobel Prize winning amino acid, L-arginine, for powerful health benefits and five
essential vitamins – C, D3, K2, B6 and B12, contributing to normal functioning of the nervous and
immune systems while reducing fatigue and promoting normal psychological function.
                <br />
                <br />
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/proargi-9">
              <img src={Pro9} className="productImage" />
            </a>
            <ListGroup.Item className="gutHealthPara">
              <div>
                <p className="gutHealthParaBold">Benefits:</p>
                <ul>
                  <li>Expands blood vessels to improve blood flow and circulation.</li>
                  <li>Supports the Immune system.</li>
                  <li>Supports Energy levels.</li>
                  <li>Featured on the Cologne List of approved supplements for athletes.</li>
                  <li>Enhances delivery of oxygen and nutrients when exercising.</li>
                  <li>Serving size individual packets for use on the go.</li>
                </ul>
                <br />
                Proargi-9+ is a great supplement for anybody who wants to improve their cardiovascular health and feel more energised. Plus it’s a phenomenal supplement for sports & fitness lovers, particularly endurance athletes.
              </div>
              <div style={{ textAlign: 'center', marginBottom: '0' }}>
                <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/proargi-9" className="btn-primary" >Shop Now</Button>
              </div>
            </ListGroup.Item>

            <br />
            <br />

            <div>
              <h6 className="guth6">Phytolife</h6>
              <ListGroup.Item className="gutHealthPara">
                <i>Your Daily Greens in just 3 teaspoons.</i>
                <br />
                <br />
                Food miles, mass production and over farming is depleting our greens of their nutrients. This product is a rich source of alkaline substances from plant extracts, including barley grass, alfalfa and peppermint. Just add to your water bottle and enjoy the refreshing minty taste, with all of the health benefits.
                <br />
                <br />
                Key Ingredients : Barley Grass Juice extract, Alfalfa Leaf & Stem juice extract, Peppermint Oil.
                <br/>
                <br/>
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/phytolife">
              <img src={Phytolife} className="productImage" />
            </a>
            <ListGroup.Item className="gutHealthPara">
              <div>
                <p className="gutHealthParaBold">Benefits:</p>
                <ul>
                  <li>Just 3 teaspoons/day equivalent to over 1kg of leafy greens.</li>
                  <li>Peppermint Oil soothes & calms the digestion system.</li>
                  <li>Creates a healthy alkaline PH in the body to counter acid forming foods.</li>
                  <li>Promotes natural blood cleansing.</li>
                  <li>Promotes toxin elimination.</li>
                  <li>Supports immune system and gut health.</li>
                </ul>
                <br />
              </div>
              <div style={{ textAlign: 'center', marginBottom: '0' }}>
                <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/phytolife" className="btn-primary" >Shop Now</Button>
              </div>
            </ListGroup.Item>
              
            <br />
            <br />

            <div>
              <h6 className="guth6">Mystify</h6>
              <ListGroup.Item className="gutHealthPara">
                <i>Your superfruits in just one shot glass.</i>
                <br />
                <br />
                If you’re struggling to get your superfruits in, this product is for you. A highly antioxidant berry juice smoothie, with Acai Berry, Green Tea extract, Vitamin B6 and a blend of other superfood concentrates including blueberry, pomegranate, bilberry, raspberry & cranberry.
                <br />
                <br />
                ‘Antioxidants are the sole protection against free radical damage and are vital to maintain a healthy life’.
                <br />
                <br />
                Key Ingredients : Acai Berry, Green Tea Extract, Vit B6 plus other Berry Juice concentrates.
                <br/>
                <br/>
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/mistify">
              <img src={Mystify} className="productImage" />
            </a>
            <ListGroup.Item className="gutHealthPara">
              <div>
                <p className="gutHealthParaBold">Benefits:</p>
                <ul>
                  <li>Immune System support.</li>
                  <li>Nervous System support.</li>
                  <li>Protects against Free radical damage.</li>
                  <li>Helps to boost energy.</li>
                  <li>Promotes normal Red Blood cell formation.</li>
                  <li>Heart healthy.</li>
                  <li>30ml shot glass equivalent to 3.5kg of super fruit.</li>
                  <li>Ready to drink</li>
                </ul>
                <br />
                Enjoy as part of your daily supplement routine to support optimum health and wellbeing. Ideal immunity booster for those who suffer with recurrent colds and flus.
              </div>
              <div style={{ textAlign: 'center', marginBottom: '0' }}>
                <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/mistify" className="btn-primary" >Shop Now</Button>
              </div>
            </ListGroup.Item>
              
            <br />
            <br />

            <div>
              <h6 className="guth6">Vitality 3 Daily System</h6>
              <ListGroup.Item className="gutHealthPara">
                <i>Proargi-9+, Mistify, Phytolife</i>
                <br />
                <br />
                Strengthen your immunity, enhance your circulation and energy levels and help to counter free radical damage, among many other things with daily use of this trio of drinks. Ideally taken as a system for maximum benefit, but can be taken in isolation.
                <br />
                <br />
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/v3-pack">
              <img src={ThreeSystem} className="productImage" />
            </a>
            <div style={{ textAlign: 'center', marginBottom: '0' }}>
              <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/v3-pack" className="btn-primary" >Shop Now</Button>
            </div>

            <br />
            <br />

            <div>
              <h6 className="guth6">E9</h6>
              <ListGroup.Item className="gutHealthPara">
                <i>A low-calorie boost when you need it most.</i>
                <br />
                <br />
                This combination of ingredients was designed to help you safely sustain your energy levels. Vitamin B12 and B6, along with pantothenic acid, not only contribute to an energy-yielding metabolism; they help reduce fatigue while supporting normal mental performance. Yerba mate extract contains properties that can strengthen the body while helping you feel more energetic. Its stimulating and tonic properties play a role in resisting mental and physical fatigue.
                <br />
                <br />
                Today, there are dozens of quick fixes for low energy, but with e9 you can trust that you are taking a product that supports your healthy lifestyle. No matter what time of day it is, if you are fighting to focus, stay alert and stay energised, mix up a serving of e9 and enjoy its refreshing, piña colada flavour.
                <br />
                <br />
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/e9">
              <img src={E9} className="productImage" />
            </a>
            <ListGroup.Item className="gutHealthPara">
              <div>
                <p className="gutHealthParaBold">Benefits:</p>
                <ul>
                  <li>Long-lasting, healthy energy through a unique, low-calorie formulation.</li>
                  <li>Contributes to normal, energy-yielding metabolism.</li>
                  <li>Plays a role in the reduction of tiredness and fatigue.</li>
                  <li>Contributes to normal psychological and mental function.</li>
                  <li>Supports the body’s natural ability to produce energy.</li>
                  <li>Contains Synergy’s flagship ingredient l-arginine.</li>
                  <li>Great-tasting piña colada flavour.</li>
                  <li>Sweetened with steviol glycosides that occur naturally in stevia leaves.</li>
                </ul>
              </div>
              <div style={{ textAlign: 'center', marginBottom: '0' }}>
                <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/e9" className="btn-primary" >Shop Now</Button>
              </div>
            </ListGroup.Item>

            <br />
            <br />

            <div>
              <h6 className="guth6">Collagen+</h6>
              <ListGroup.Item className="gutHealthPara">
                <i>Powerful support for total body benefits.</i>
                <br />
                <br />
                Collagen is a fibrous protein of connective tissues that provides structural support and enhanced strength to a variety of body tissues including hair, skin, nails, muscles, bones, and cartilage. Overall, collagen comprises one-third of the total proteins in our body. Synergy Collagen+ offers all the benefits of collagen plus Vitamin C, Hyaluronic Acid, and Zinc. This synergistic blend of vitamins and nutrients supports collagen production, hydration, and normal protein synthesis for the whole body, giving you powerful support for total body benefits.
                <br />
                <br />
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/collagen">
              <img src={COL} className="productImage" />
            </a>
            <ListGroup.Item className="gutHealthPara">
              <div>
                <p className="gutHealthParaBold">Benefits:</p>
                <ul>
                  <li>Supports collagen production throughout the body.</li>
                  <li>Supports a normal protein synthesis.</li>
                  <li>Supports Energy levels.</li>
                  <li>Strengthens body tissues including hair, skin, nails, muscles, bones, and cartilage.</li>
                </ul>
                <br />
              </div>
              <div style={{ textAlign: 'center', marginBottom: '0' }}>
                <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/collagen" className="btn-primary" >Shop Now</Button>
              </div>
            </ListGroup.Item>
            
            <div>
              <h6 className="guth6">Essential Greens</h6>
              <ListGroup.Item className="gutHealthPara">
                <i>Everyday power through pure green goodness.</i>
                <br />
                <br />
                Healthy fibres and phytonutrients from a multitude of carefully selected quality sources helps Essential Greens bridge nutritional gaps found in diets, whilst supporting the whole body and microbiome.
                <br />
                <br />
                Meticulously designed and formulated by the Hughes Centre for Research and Innovation for Synergy WorldWide Europe, our Essential Greens deliver greens and vegetables in an easy to consume and convenient form.
                <br />
                <br />
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/essential-greens">
              <img src={EssentialG} className="productImage" />
            </a>
            <ListGroup.Item className="gutHealthPara">
              <div style={{ textAlign: 'center', marginBottom: '0' }}>
                <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/essential-greens" className="btn-primary" >Shop Now</Button>
              </div>
            </ListGroup.Item>

          </Col>
        </Row>
      </Container>
      <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', height: '18vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiOutlineCopyright />&nbsp;
          <p style={{ marginBottom: 0 }}>Copyright Laura Ramuckiene 2024</p>
        </div>
        {/* <p>Made by Jahnvi Patel</p> */}
      </div>
    </>
  )
}

export default Shop


