import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import InsuranceLogo from '../assets/InsuranceLogo.jpg'
import { AiOutlineCopyright } from 'react-icons/ai'


const Qualifications = () => {

  return (
    <>
      <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
      <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100vw', position: 'relative' }}>
        <h6 style={{ margin: '0.25rem 0', fontSize: '4vh', textAlign: 'center', padding: '5vh 0 5vh' }}>Qualifications</h6>
      </div>
      <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '3rem' }}></div>
      <Row style={{ minHeight: '60vh', width: '100vw', display: 'flex', alignItems: 'center', margin: '0', paddingTop: '0vh', justifyContent: 'center', color: 'white' }}>
        <Col sm={7} style={{ padding: '2vw 2vh' }}>
          <div className="about-text-container">
            <div style={{ color: '#0C3751', fontSize: '18px', textAlign: 'left' }}>
            ~ Pregnancy/Postnatal Massage Practitioner Diploma, Elemental Massage School, 2024
              <br />
              <br />
              ~ Thai Herbal Poultice Facial Massage Certification, Cecily Braden Spa Wellness Academy, 2024
              <br />
              <br />
              ~ Eastern European Lifting certification accredited by BABTAC, 2023
              <br />
              <br />
              ~ RockTape Functional Movement Taping Level 1 &amp; Level 2, RockDoc Certified, 2023
              <br />
              <br />
              ~ RockBlades assisted soft tissue mobilisations IASTM, FTM Certified, 2023
              <br />
              <br />
            ~ Face-Remodelling using intraoral massage, multilevel face massage, cupping and facetaping techniques certification accredited by BABTAC, 2023
              <br />
              <br />
            ~ Body Sculpting Maderotherapy. Elemental Massage school, 2023
              <br />
              <br />
            ~ Thai Yoga Massage Advanced training, Kao Sok, Thailand, 2023
              <br />
              <br />
            ~ Practitioners Diploma in Beauty Facial, 2022<br />
              <br />
~ RENATA FRANÇA METHOD Shaping certification course, 2022<br />
              <br />
~ RENATA FRANÇA METHOD Miracle Face certification course, 2022<br />
              <br />
~ RENATA FRANÇA METHOD Lymphatic Drainage certification course, 2022<br />
              <br />
~ 200 Hour Yoga Teacher Training Course, Samadhi Yoga Ashram School, 2022/2023<br />
              <br />
~ Thai Medicinal Herbs Workshop with Bob Haddad, 2022<br />
              <br />
~ Hawaiian Lomi Lomi Massage Course with Ricardo Balkhoven, 2022<br />
              <br />
~ Fire Cupping Certification at London Institute of Thai Yoga Massage, 2022<br />
              <br />
~ Practitioners Diploma in Gut Health, 2022<br />
              <br />
~ Fundamentals of AYURVEDA course, 2022<br />
              <br />
~ Hot and Cold Stone Massage Diploma, 2022<br />
              <br />
~ Natural Lift Facial Massage/Facial Rejuvenation Diploma, 2021<br />
              <br />
~ Warm Bamboo Massage Diploma, 2021<br />
              <br />
~ Diploma in Myofascial Release, 2021<br />
              <br />
~ Advanced Thai Yoga Massage in Motion course at Mudita School of Thai Yoga Massage, 2021<br />
              <br />
~ Practitioners Diploma in Manual Lymphatic Drainage Massage, 2021<br />
              <br />
~ Therapies and Sports Massage Practitioners Diploma in Advanced and Deep Tissue Massage, 2021<br />
              <br />
~ ITEC Level 2 in Infection Prevention for Complimentary Therapies, 2021<br />
              <br />
~ ITEC Level 3 Diploma in Massage 2021<br />
              <br />
~ ITEC Level 3 Certificate in Anatomy, Physiology, Pathology 2019<br />
              <br />
~ Thai Foot Massage course at The School of Thai Yoga Massage, 2019<br />
              <br />
~ Thai Pregnancy Massage course at The School of Thai Yoga Massage, 2019<br />
              <br />
~ Certification in advanced Thai Yoga Massage with feet at Mudita School of Thai Yoga Massage, 2019<br />
              <br />
~ Certification in advanced Rhythm and Rocking – Dynamic Thai Massage at Mudita School of Thai Yoga Massage, 2018<br />
              <br />
~ Thai Yoga Massage Practitioner Training at Mudita School of Thai Yoga Massage, 2017<br />
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', marginBottom: '0', height: '17vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <img src={InsuranceLogo} className="insuranceLogo" />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '1vh' }}>
          <AiOutlineCopyright />&nbsp;
          <p style={{ marginBottom: 0 }}>Copyright Laura Ramuckiene 2022</p>
        </div>
      </div>
    </>
  )
}

export default Qualifications
