import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ListGroup from 'react-bootstrap/ListGroup'
import PurifyKit from '../assets/PurifyKit.png'
import Button from 'react-bootstrap/Button'
import { AiOutlineCopyright } from 'react-icons/ai'

const GutHealth = () => {
  return (
    <>
      <Container fluid style={{ display: 'flex', justifyContent: 'center', width: '100vw' }}>
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <div>
              {/* <h6 className="firsth6">Gut Purify</h6> */}
              <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
              <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100%', position: 'relative' }}>
                <h6 style={{ margin: '0.25rem 0', fontSize: '4vh', textAlign: 'center', padding: '5vh' }}>Gut Microbiome</h6>
              </div>
              <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '3rem' }}></div>

              <ListGroup.Item className="gutHealthPara">
                The human body is host to trillions of microbes or bacteria. Some of these are useful, and some are harmful. Collectively, they are known as your gut microbiome, and they’re incredibly important for overall health. 
                <br/>
                <br/>
                The gut microbiome of each individual is unique. It can heavily contribute to how a person fights disease, digests food, and even their mood. Resetting your microbiome (gut flora) is the secret to optimal well-being – not only physically but also mentally and emotionally.
                <br/>
                <br/>
                <strong>Click the following link to learn about the gut microbiome &nbsp; - </strong>
                <a className="gutHealthLinks" style={{ color: '#66a1a8' }} href="https://www.youtube.com/watch?v=G38O7gmqzVI&t=1s&ab_channel=SynergyWorldWide" >&nbsp;<i>What is the Microbiome? (English) - YouTube</i></a>
                <br/>
                <br/>
                <strong>Signs your microbiome may need support:</strong>
                <br/>
                <br/>
                <ul>
                  <li>Bloating</li>
                  <li>Constipation</li>
                  <li>Stomach cramps</li>
                  <li>Chronic fatigue</li>
                  <li>Unexpected weight gain </li>
                  <li>Skin problems (acne, eczema) </li>
                  <li>Mood swings</li>
                  <li>Lack of concentration and focus</li>
                  <li>Sugar cravings</li>
                  <li>Weak immunity</li>
                </ul>
                <br/>
                Fortunately, just like there are a lot of things that can contribute to a gut imbalance, there are a lot of things you can do to support your gut health and bring your body back into balance. 
                <br/>
                <br/>
                <a href='https://www.whypurify.co.uk/lr-healthcheck/' className="gutHealthLinks" style={{ color: '#66a1a8' }}>Health Check</a>
                <br/>
                <a href='https://www.whypurify.co.uk/?tm=lr' className="gutHealthLinks" style={{ color: '#66a1a8' }}>Why Purify?</a>
              </ListGroup.Item>
            </div>
            <br />
            <br />
            <div>
              {/* <h6 className="guth6">21-Day Programme</h6> */}
              <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
              <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100%', position: 'relative' }}>
                <h6 className="twentyOneDayTitle" style={{ margin: '0.25rem 0', fontSize: '4vh', textAlign: 'center', padding: '5vh 0 5vh' }}>21 Day Gut Reset Programme</h6>
              </div>
              <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '3rem' }}></div>
              <ListGroup.Item className="gutHealthPara">
              Target the roots of your health with Synergy’s Purify programme. Comprised of powerful, patent-pending products, Purify has been clinically validated to give your microbiome the kickstart it needs to fuel human performance.
                <br/>
              To follow the programme, you start with a ‘detox week’, removing caffeine, dried/bulk carbs, starchy vegetables, ready-made foods and sweets from your diet. Then you start on the Purify Kit as directed for intense 7 days still eating from Purify friendly food list. The Biome Kit provides probiotics, vegetable proteins, fibre, and many powerful nutrient blends.
                <br/>
                <br/>
                <a className="gutHealthLinks" href="https://www.youtube.com/watch?v=N-a3ZbYHH7U&ab_channel=EliteHealth"><i>Click here to learn about the Purify Programme by Synergy</i></a>
              </ListGroup.Item>
            </div>
            <a className="gutHealthLinks" href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/purify-kit">
              <img src={PurifyKit} className="productImage" />
            </a>
            <ListGroup.Item className="gutHealthPara">
              <div>
                <p style={{ fontSize: '20px', fontWeight: 'bolder' }} >The programme features:</p>
                <ul>
                  <li>Initial 1-2-1 consultation to discuss goals and lifestyle analysis.</li>
                  <li>A comprehensive guide to the programme.</li>
                  <li>Supplements to prepare the body for detox.</li>
                  <li>Supplements for the intense 7-day detox Purify stage.</li>
                  <li>Mentor support throughout as well as group support.</li>
                  <li>End of 21-day wellbeing consultation to compare graphs, discuss changes and a forward plan to continue improving your health.</li>
                </ul>
              </div>
            </ListGroup.Item>
            <div>
              <h6 className="guth6">Key Products</h6>
              {/* <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100vw' }}>
                <h6 style={{ margin: '0', fontSize: '4vh', textAlign: 'center', padding: '5vh 0 5vh', marginBottom: '5vh' }}>Key Products</h6>
              </div> */}
              <ListGroup.Item className="gutHealthPara">
                <h4 style={{ fontSize: '20px', margin: '3vh 0 1vh', fontWeight: 'bolder' }}>Biome DT</h4>
                <h6 className="gutHealthPara" style={{ fontSize: '18px' }} >Utilises three distinct pathways to provide breakthrough purification, by activating detoxification,
                cleansing with fibre to eliminate toxins and balancing the microbiome. This purification drink is a
                patent- pending formula.</h6>
                {/* <br /> */}
                <ul>
                  <li>Detoxifies, cleanses and balances the microbiome.</li>
                  <li>
                  Lubricates the digestive tract to facilitate contaminant elimination.
                  </li>
                  <li>Activates enzymes to target contaminants including heavy metals.</li>
                  <li>Aids healthy bacteria with pH-balancing fermentable fibre.</li>
                  <li>Supports healing of the digestive lining with L-Glutamine.</li>
                </ul>
                <br/>
                <h4 style={{ fontSize: '20px', margin: '3vh 0 1vh', fontWeight: 'bolder' }}>Biome Shake</h4>
                <h6 className="gutHealthPara" style={{ fontSize: '18px' }}>Vegan friendly purifying meal replacement shake high in vegetable protein with a blend of
antioxidants, vitamins, minerals, amino acids and beneficial fats. These fermentable fibres create the
right environment in the gut for good bacteria to flourish. As part of the Purify program, Biome
Shake helps to balance and purify the microbiome with vegetable base featuring broccoli, digestive
enzymes, prebiotics and clean vegetable protein.</h6>
                <br />
                <h4 style={{ fontSize: '20px', margin: '3vh 0 1vh', fontWeight: 'bolder' }}>Proargi-9+</h4>
                <h6 className="gutHealthPara" style={{ fontSize: '18px' }}>Contains the Nobel Prize winning amino acid, L-arginine, for powerful health benefits and five
essential vitamins – C, D3, K2, B6 and B12, contributing to normal functioning of the nervous and
immune systems while reducing fatigue and promoting normal psychological function.</h6>
                <br />
                <h4 style={{ fontSize: '20px', margin: '3vh 0 1vh', fontWeight: 'bolder' }}>Body Prime</h4>
                <h6 className="gutHealthPara" style={{ fontSize: '18px' }}>Made with magnesium, prune powder and apple pectin, this formula has simple ingredients that
support cleansing and bowel motility to prime the body for an effective purification program.</h6>
                {/* <br/> */}
                <ul>
                  <li>Magnesium helps to maintain healthy electrolyte balance and reduce fatigue.</li>
                  <li>Contributes to muscle function including the heart muscle.</li>
                  <li>Aids with muscle contraction including natural heartbeat.</li>
                  <li>Supports cleansing and bowel motility.</li>
                </ul>
                <br/>

                <h4 style={{ fontSize: '20px', margin: '3vh 0 1vh', fontWeight: 'bolder' }}>Biome Actives</h4>
                <h6 className="gutHealthPara" style={{ fontSize: '18px' }}>Combines both prebiotics and probiotics to provide a favourable environment for beneficial gut
bacteria. This helps maintain the balance of microbiome in the digestive system and supports
healthy detoxification.</h6>
                {/* <br/> */}
                <ul>
                  <li>Provides 1 billion CFU (colony forming units) of Bacillus coagulans per capsule.</li>
                  <li>Helps healthy bacteria by optimising pH-balance with lactic acid-forming bacteria.</li>
                  <li>Feeds healthy bacteria with prebiotics.</li>
                  <li>Supports healthy immune function.</li>
                </ul>
                <br />
              </ListGroup.Item>
            </div>
            <div style={{ textAlign: 'center', marginBottom: '0' }}>
              <Button style={{ margin: '5vh' }} href="https://lauraramu.new.synergyworldwide.com/GB/en-gb/Shop/Product/purify-kit" className="btn-primary">Shop Now</Button>
            </div>
            <br />
            <br />
            <div>
              <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
              <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '110vw', position: 'relative' }}>
                <h6 style={{ margin: '0.25rem 0', fontSize: '4vh', textAlign: 'center', padding: '5vh 0' }}>How can we help you?</h6>
              </div>
              <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '3rem' }}></div>
              <ListGroup.Item className="gutHealthPara">
                {/* How can we help you? */}
              By answering a few questions about your lifestyle and how you are currently feeling, we can gain an insight into how your bodily systems are operating from very poor to very good levels of health.
                <br />
                <br />
              This gives us a highlight of which bodily systems need the most help.
                <br />
                <br />
              For a FREE consultation and Lifestyle Analysis please email <strong>laura.tytherapy@gmail.com</strong>
                <br />
                <br />
                <br />
                <br />
              </ListGroup.Item>
            </div>
          </Col>
        </Row>
      </Container>
      <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', height: '18vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiOutlineCopyright />&nbsp;
          <p style={{ marginBottom: 0 }}>Copyright Laura Ramuckiene 2022</p>
        </div>
        {/* <p>Made by Jahnvi Patel</p> */}
      </div>
    </>
  )
}

export default GutHealth
