import React from 'react'
import Container from 'react-bootstrap/Container'
import HomeImage from '../assets/0R3A4803.jpg'
import Button from 'react-bootstrap/Button'
import Footer from './Footer'
import Plant from '../assets/plant.jpg'

const Home = () => {
  return (
    <>
      <Container fluid className="homepage-image" style={{ padding: '0', backgroundImage: `url(${HomeImage})` }}>
        <div fluid className="shadow"></div>
        <div
          className="homepage-div" style={{ display: 'flex', position: 'absolute', width: '100vw', justifyContent: 'center', bottom: '20%' }}
        >
          <div fluid className="homepage-text">
            <>
              Transform your wellbeing,
              <br />
            balance the body&#39;s energy pathways,
              <br />
            enrich emotional calm and deep relaxation.
              {/* <div>
                <Button href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="nav-button homepage-book-button" >Book A Treatment</Button>
              </div> */}
            </>
          </div>
        </div>
      </Container>
      <Container style={{ width: '100vw', padding: '0' }} fluid>
        <div style={{ display: 'inline-block', height: 'fit-content' }} className="homepage-paragraph">
          Everyday demands can leave us feeling stressed and overwhelmed, unable to step back and unwind, depleted of energy and lack of luster.
          <br />
          <br />
          Massage therapy is a powerful tool to recharge, rebalance, rewind and relax your body and mind. By easing muscular tension and aiding relaxation, massage can help to prevent physical pain and also release psychological tension and emotions that can manifest as physical symptoms
        </div>
        {/* </Container> */}
        {/* <Container fluid style={{ padding: '0', width: '100vw' }}> */}
        <div style={{ textAlign: 'center', color: 'whitesmoke', marginBottom: '0', height: '15vh', width: '100vw' }}>
          <Button style={{ margin: '0 0 3vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="nav-button" >Book Now</Button>
        </div>

        <Container fluid className="plant-image" style={{ padding: '0', backgroundImage: `url(${Plant})` }}>
          {/* <div
            className="homepage-div" style={{ display: 'flex', position: 'absolute', width: '100vw' }}
          > */}
          <div fluid className="homepage-plant-text">
            <i className='plant-text'>&quot; Where there&apos;s stagnation, there will be pain. 
              <br/>
          Remove the stagnation, and you remove the pain. &quot;</i>
          </div>
          {/* </div> */}
        </Container>
      </Container>
      <Footer />
    </>


  )
}

export default Home
