import React from 'react'
import MyNavbar from './components/Navbar.js'
import Home from './components/Home.js'
import Treatments from './components/Treatments.js'
import Location from './components/Location.js'
import About from './components/About.js'
import TermsAndConditions from './components/TermsAndConditions.js'
import GutHealth from './components/GutHealth.js'
import Shop from './components/Shop.js'
import Gallery from './components/Gallery.js'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Face from './components/Face.js'
import Qualifications from './components/Qualifications.js'




function App() {
  return (
    <BrowserRouter>
      <MyNavbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/body-treatments">
          <Treatments />
        </Route>
        <Route path="/gut-health">
          <GutHealth />
        </Route>
        <Route path="/contact-us">
          <Location />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/terms&conditions">
          <TermsAndConditions />
        </Route>
        <Route path="/shop">
          <Shop />
        </Route>
        <Route path="/gallery">
          <Gallery />
        </Route>
        <Route path="/face-treatments">
          <Face />
        </Route>
        <Route path="/qualifications">
          <Qualifications />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
