import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import Footer from './Footer'
import { TiTick } from 'react-icons/ti'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'


const Face = () => {


  return (
    <>
      <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
      <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100vw' }}>
        <h6 style={{ margin: '0.25rem 0', fontSize: '4vh', textAlign: 'center', padding: '5vh 0 5vh' }}>Face Treatments</h6>
      </div>
      <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '3rem' }}></div>

      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
        <span id="treatments" className="offset"></span>

        <Row fluid style={{ width: '100vw', padding: '2vh 5vh', justifyContent: 'space-evenly', fontSize: '18px' }}>
          <Col sm={5} style={{ display: 'flex', justifyContent: 'center', padding: '0' }}>
            <ListGroup variant="flush">

              <div>
                <h6 id="naturalFacial" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>FACE REMODELLING</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>40 min - £60  / 60 min - £100 / 5 x 60 min - £425 </h5>
                <ListGroup.Item className="treatment-descriptions">
                Face Remodelling is a unique and innovative method of face rejuvenation with individual approach to each client, combining different multilevel massage techniques and accessories. Combination of advanced techniques enables to reach deepest structures of the face, allowing correction of face shape and its asymmetry, improvement of muscles tone, stimulation of lymph and blood flow and reduction of muscle spasms, which can be the main reason for wrinkles and fine lines creation. Face Remodelling massage techniques consists of:
                  <br />
                  <br />
                  <TiTick />&nbsp;Sculpted-out Multilevel Massage - working on muscles, fascia and fatty tissue.
                  <br />
                  <TiTick />&nbsp;Sculpted- in Intraoral Buccal massage.
                  <br />
                  <TiTick />&nbsp;Vacuum Therapy
                  <br />
                  <TiTick />&nbsp;Facetaping
                  <br />
                  <br />
                  <Accordion flush className="treatment-readmores" defaultActiveKey="1">
                    <Card style={{ border: 'none' }}>
                      <Card.Header style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
                        <Accordion.Toggle style={{ textAlign: 'left', color: 'black', backgroundColor: 'white', padding: '0', fontStyle: 'italic' }}
                          eventKey="0">
                          READ MORE...
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '2vh 0 0' }}>
                          <u>Benefits of the Sculpted-out massage:</u>
                          <br />
                          <br />
                          <div style={{ paddingLeft: '35px' }}>
                            <TiTick />&nbsp;Reduces mimic lines on the forehead and frown lines.
                            <br />
                            <TiTick />&nbsp;Reduces nasal labial fold lines and lip corner lines.
                            <br />
                            <TiTick />&nbsp;Lifts eyebrows and opens eyes.
                            <br />
                            <TiTick />&nbsp;Reduces eyelid puffiness and eye bags.
                            <br />
                            <TiTick />&nbsp;Lifts cheekbone area.
                            <br />
                            <TiTick />&nbsp;Redefines face oval and shapes.
                            <br />
                            <TiTick />&nbsp;Improves face symmetry.
                            <br />
                            <TiTick />&nbsp;Holistic alternative to Botox and fillers.
                            <br />
                            <TiTick />&nbsp;Stimulates acupressure points.
                            <br />
                            <TiTick />&nbsp;Reduces double chin.
                            <br />
                            <TiTick />&nbsp;Lengthens neck and improves its shape, skin texture and lines.
                            <br />
                            <TiTick />&nbsp;Reduces declination of blood capillaries and helps to create new ones.
                            <br />
                            <TiTick />&nbsp;Improves skins texture, its colour and elasticity.
                            <br />
                            <TiTick />&nbsp;Improves blood and lymph circulation.
                            <br />
                            <TiTick />&nbsp;Improves muscle tone.
                            <br />
                            <TiTick />&nbsp;Reduces puffiness, congestion, cramps and acne.
                            <br />
                            <TiTick />&nbsp;Helps to reduce bruxism.
                          </div>
                          <br />
                          <br />
                          <u>Benefits of the Sculpted-in massage:</u>
                          <br />
                          <br />
                          <div style={{ paddingLeft: '35px' }}>
                            <TiTick />&nbsp;Jaw tension and stress relief.
                            <br />
                            <TiTick />&nbsp;Alleviates headaches and migraines.
                            <br />
                            <TiTick />&nbsp;Contours and sculpts your face.
                            <br />
                            <TiTick />&nbsp;Reduces lines and wrinkles around the jaw area.
                            <br />
                            <TiTick />&nbsp;De-puffs.
                            <br />
                            <TiTick />&nbsp;Stimulates the lymphatic system.
                          </div>
                          <br />
                          <br />
                          <u>Benefits of vacuum cupping:</u>
                          <br />
                          <br />
                          <div style={{ paddingLeft: '35px' }}>
                            <TiTick />&nbsp;Stimulates blood circulation.
                            <br />
                            <TiTick />&nbsp;Activates creation of new blood capillaries.
                            <br />
                            <TiTick />&nbsp;Nourishes skin and muscles.
                            <br />
                            <TiTick />&nbsp;Reduces lymph stagnation.
                            <br />
                            <TiTick />&nbsp;Loosens muscles.
                            <br />
                            <TiTick />&nbsp;Detoxifies skin.
                            <br />
                            <TiTick />&nbsp;Gives better skin tone.
                            <br />
                            <TiTick />&nbsp;Improves collagen fibres.
                          </div>
                          <br />
                          <br />
                          <u>Benefits of face-taping:</u>
                          <br />
                          <br />
                          <div style={{ paddingLeft: '35px' }}>
                            <TiTick />&nbsp;Reduces muscle fatigue.
                            <br />
                            <TiTick />&nbsp;Improves lymphatic drainage and blood flow.
                            <br />
                            <TiTick />&nbsp;Enhances healing, such as reducing oedema.
                            <br />
                            <TiTick />&nbsp;Stabilises muscles.
                            <br />
                            <TiTick />&nbsp;Smooths out wrinkles.
                          </div>

                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>
              
              <div>
                <h6 id="naturalFacial" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>EASTERN EUROPEAN LIFTING</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>40 min - £75  / 5 x 40 min - £320 </h5>
                <ListGroup.Item className="treatment-descriptions">
                  Powerful and energetic face massage with firm pressure and precision of the movements that gives spectacular lifting, toning and sculpting effects. 
                  <br />
                  <br />
                  <Accordion flush className="treatment-readmores" defaultActiveKey="1">
                    <Card style={{ border: 'none' }}>
                      <Card.Header style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
                        <Accordion.Toggle style={{ textAlign: 'left', color: 'black', backgroundColor: 'white', padding: '0', fontStyle: 'italic' }}
                          eventKey="0">
                          READ MORE...
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '2vh 0 0' }}>
                          Benefits:
                          <br />
                          <br />
                          <div style={{ paddingLeft: '35px' }}>
                            <TiTick />&nbsp;Increases blood and lymph circulation.
                            <br />
                            <TiTick />&nbsp;Plumps the skin.
                            <br />
                            <TiTick />&nbsp;Reduce expression lines and wrinkles.
                            <br />
                            <TiTick />&nbsp;Helps to eliminate toxins.
                            <br />
                            <TiTick />&nbsp;Improves appearance of the skin.
                            <br />
                            <TiTick />&nbsp;Stimulates and relaxes muscles.
                            <br />
                            <TiTick />&nbsp;Reduces tension in the face and neck.
                            <br />
                            <TiTick />&nbsp;Stimulates nerve endings.
                            <br />
                            <TiTick />&nbsp;Reduces wrinkles.
                            <br />
                            <TiTick />&nbsp;Slows down ageing process.
                            <br />
                            <TiTick />&nbsp;Activates skin`s metabolism.
                            <br />
                            <TiTick />&nbsp;Gives lifting effects – Botox feel.
                            <br />
                            <TiTick />&nbsp;Speeds up skin&apos;s regeneration
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

            </ListGroup>
          </Col>

          <Col sm={5} style={{ display: 'flex', justifyContent: 'center', padding: '0' }}>
            <ListGroup variant="flush">
              
              <div>
                <h6 id="lymphD" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>MIRACLE FACE<br />
                  <i>Renata França Method</i></h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>30 min - £60 / 60 min - £100</h5>
                <ListGroup.Item className="treatment-descriptions">
                Designed to produce immediate lifting effect, this facial massage might reduce swelling, accentuate the face shape and promote natural skin revitalization. The Face França Method Miracle Face aims at draining swelling and providing a new contour for the face blending Lymphatic Drainage and Shaping Massage maneuvers, it promises results as special as its body version, the Miracle Touch.
                  <br />
                  <br />
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>
              
              
            </ListGroup>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  )
}

export default Face
