import React from 'react'
import InsuranceLogo from '../assets/InsuranceLogo.jpg'
import { AiOutlineCopyright } from 'react-icons/ai'

const Footer = () => {
  return (
    <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '1.5vh 0' }}>
      <img src={InsuranceLogo} className="insuranceLogo" />
      <a href="/terms&conditions" className="tcLink" style={{ fontSize: '19px', margin: '2vh 0 1vh' }} >Terms & Conditions
      </a>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <AiOutlineCopyright />&nbsp;
        <p style={{ marginBottom: 0 }}>Copyright Laura Ramuckiene 2024</p>
      </div>
      {/* <p>Made by Jahnvi Patel</p> */}
    </div>
  )
}

export default Footer
