import React, { useState } from 'react'
import { ReactComponent as BlackLogo } from '../assets/blackLogo.svg'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import { FaInstagram } from 'react-icons/fa'
// import ButtonGroup from 'react-bootstrap/ButtonGroup'
// import Dropdown from 'react-bootstrap/Dropdown'
// import DropdownButton from 'react-bootstrap/DropdownButton'


const MyNavbar = () => {

  const [expanded, setExpanded] = useState(false)

  // const [show, setShow] = useState(false)
  // const showDropdown = () => {
  //   setShow(!show)
  // }
  // const hideDropdown = () => {
  //   setShow(false)
  // }

  return (
    <Navbar expanded={expanded} sticky="top" expand="lg" className="justify-space-between" 
      style={{ padding: '0 2vw' }}
    >
      <Navbar.Brand href="/">
        <BlackLogo style={{ height: '8vh' }} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : 'expanded')} />
      <Navbar.Collapse
        className='justify-content-end transparentise'>
        <Nav className="align-items-center" >
          <a className="nav-link" href="/about" >About</a>
          <a className="nav-link" href="/body-treatments" >Body</a>
          {/* <ButtonGroup className="nav-link"> */}
          {/* {window.innerWidth <= 760 ? 
              <DropdownButton variant="none" as={ButtonGroup} title="Treatments" id="bg-nested-dropdown"
                onMouseEnter={showDropdown} 
                onMouseLeave={hideDropdown}
              >
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#thaiYoga">Thai Yoga Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#thaiFoot">Thai Foot Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#swedishMassage">Swedish Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#deepTissue">Deep Tissue Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#thaiPregnancy">Thai Pregnancy Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#fireCupping">Fire Cupping</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#bamboo">Warm Bamboo Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#hotAndCold">Hot And Cold Stone Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#myofascial">Myofascial Release</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#lomi">Lomi Lomi</Dropdown.Item>
                Vodder Method
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#manualLymph">Manual Lymphatic Drainage</Dropdown.Item>
                Renata França Method
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#lymphD">Lymphatic Drainage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#sculptingMassage">Sculpting Massage</Dropdown.Item>
              </DropdownButton>
              :
              <DropdownButton variant="none" as={ButtonGroup} title="Treatments" id="bg-nested-dropdown"
                show={show}
                onMouseEnter={showDropdown} 
                onMouseLeave={hideDropdown}
              >
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#thaiYoga">Thai Yoga Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#thaiFoot">Thai Foot Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#swedishMassage">Swedish Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#deepTissue">Deep Tissue Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#thaiPregnancy">Thai Pregnancy Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#fireCupping">Fire Cupping</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#bamboo">Warm Bamboo Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#hotAndCold">Hot And Cold Stone Massage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#myofascial">Myofascial Release</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#lomi">Lomi Lomi</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#manualLymph">Manual Lymphatic Drainage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#lymphD">Lymphatic Drainage</Dropdown.Item>
                <Dropdown.Item onClick={() => setExpanded(false)} href="/treatments#sculptingMassage">Sculpting Massage</Dropdown.Item>
              </DropdownButton>
            } */}
          {/* </ButtonGroup> */}
          <a className="nav-link" href="/face-treatments">Face</a>
          <a className="nav-link" href="/gut-health">Gut Health</a>
          <a className="nav-link" href="/shop">Shop</a>
          <a className="nav-link" href="/contact-us">Contact Us</a>
          <a className="nav-link" href="/gallery">Gallery</a>
          <a className="nav-link" href="https://www.instagram.com/laura.thaiyogatherapy/" style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
            <FaInstagram className="insta-icon" />
          </a>
          <Button href="https://app.acuityscheduling.com/schedule.php?owner=19104690"
            className="nav-link"
            id="nav-button"
            style={{ marginLeft: '1vw' }}
          >Book Now</Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MyNavbar
