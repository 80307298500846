import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Footer from './Footer'

const TermsAndConditions = () => {
  return (
    <>
      <Container fluid style={{ display: 'flex', justifyContent: 'center', width: '100vw' }}>
        <span id="location" className="offset"></span>
        <Row>
          <Col style={{ textAlign: 'left' }}>
            <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
            <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100vw' }}>
              <h6 style={{ margin: '0.25rem 0', fontSize: '4vh', textAlign: 'center', padding: '5vh 0 5vh' }}>Terms & Conditions</h6>
            </div>
            <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '3rem' }}></div>
            <br />
            <br />
            <div className="tcSubContainer">
              <h6 className="tcHeading">Before Session</h6>
            </div>
            <div className="tcDiv">
            We ask that you arrive 10 minutes prior to your scheduled appointment to ensure you benefit from the fully allocated treatment time. We cannot be held responsible for shortened treatments due to late arrivals and the full treatment price will be charged.
            </div>
            <br />
            <br />

            <div className="tcSubContainer">
              <h6 className="tcHeading">Consultations</h6>
            </div>
            <div className="tcDiv">
            You will be required to complete a consultation form before your treatment starts on your first visit.
            </div>
            <br />
            <br />

            <div className="tcSubContainer">
              <h6 className="tcHeading">Cancellations</h6>
            </div>
            <div className="tcDiv">
            We have a 24-hour cancellation policy due to high client demand. Failure to notify us within 24 hours will result in the charge of the full consultation fee.
            </div>
            <br />
            <br />

            <div className="tcSubContainer">
              <h6 className="tcHeading">Refunds</h6>
            </div>
            <div className="tcDiv">
            For therapies purchased, we do offer gift vouchers as an alternative.
            </div>
            <br />
            <br />

            <div className="tcSubContainer">
              <h6 className="tcHeading">Gift Vouchers</h6>
            </div>
            <div className="tcDiv">
            Gift vouchers can be purchased by request. They are available for any treatment or any amount and are valid for 12 months after purchase. Gift cards are transferable but non-refundable.
            </div>
            <br />
            <br />
          </Col>
        </Row>
      </Container >
      <Footer />
    </>
  )
}

export default TermsAndConditions
