import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import { TiTick } from 'react-icons/ti'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Footer from './Footer'


const Treatments = () => {


  return (
    <>
      <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
      <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100vw' }}>
        <h6 style={{ margin: '0.25rem 0', fontSize: '4vh', textAlign: 'center', padding: '5vh 0 5vh' }}>Body Treatments</h6>
      </div>
      <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '3rem' }}></div>

      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
        <span id="treatments" className="offset"></span>

        <Row fluid style={{ width: '100vw', padding: '2vh 5vh', justifyContent: 'space-evenly', fontSize: '18px' }}>
          <Col sm={6} style={{ display: 'flex', justifyContent: 'center', padding: '0' }}>
            <ListGroup variant="flush">

              <div>
                <h6 id="thaiYoga" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>THAI YOGA MASSAGE</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>60 min - £85 / 90 min - £120 / 2 hr - £140 / 10% off package of 3 / 15% off package of 5 / 20% off package of 10</h5>
                <ListGroup.Item className="treatment-descriptions">
                  A Thai Yoga Massage is a powerful form of energy rebalancing and physical massage. Thai medicine is based on the principle that areas where energy stagnates or is depleted cause imbalance or blockages in the body. Any imbalance or blockage (physical and emotional knots) in the distribution of this energy can cause tension, pain and disease.
                  <br />
                  <br />
                  The aim of a Thai Yoga massage is to optimize energy flow through channels in the body known as Sen lines to balance energy and promote healing and health in the body and mind.
                  <br />
                  <br />
                  This therapeutic style of massage incorporates a combination of Acupressure, Yoga-like stretching, Reflexology, Osteopathy, Meditation and Energy healing.
                  <br />
                  <br />
                  <Accordion flush className="treatment-readmores" defaultActiveKey="1">
                    <Card style={{ border: 'none' }}>
                      <Card.Header style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
                        <Accordion.Toggle style={{ textAlign: 'left', color: 'black', backgroundColor: 'white', padding: '0', fontStyle: 'italic' }}
                          eventKey="0">
                          READ MORE...
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '2vh 0 0' }}>
                          During a Thai Yoga Massage, the practitioner uses a range of techniques and “body work” to remove the restriction to the flow of energy throughout the body to achieve balance and harmony and optimize health. When the system is working well and the energy distribution is balanced you feel happy, relaxed, energetic and free from stiffness and pain.
                          <br />
                          <br />
                          Clinical studies have shown that a Thai Yoga Massage can prevent bone degeneration, increase range of motion and muscular strength, reduce stress, treat back pain, and contribute to psychological well-being.
                          <br />
                          <br />
                          A traditional Thai Yoga Massage is carried out on the floor on a futon and the client remain fully clothed in comfortable clothing such as those used for a yoga class that doesn’t restrict movements. No oil is used on the body.
                          <br />
                          <br />
                          A typical session lasts 2 hours, although a good treatment can take only 1.5 hours if needed.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="thaiFoot" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>THAI FOOT MASSAGE</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>60 min - £85</h5>
                <ListGroup.Item className="treatment-descriptions">
                  A Thai Foot Massage is a relaxing and invigorating treatment to the feet and lower legs. It involves hands on massage and stretching techniques along with the use of the traditional Thai acupressure stick. Unlike reflexology, a Thai Foot Massage is not diagnostic, but works to stimulate all the reflex zones in the feet, removes stiffness, improves flexibility of the feet and ankles, and promote general wellbeing.
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="swedishMassage" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>SWEDISH MASSAGE</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>60 min - £85 / 90 min - £120</h5>
                <ListGroup.Item className="treatment-descriptions">
                  A Swedish Massage involves soft, long, flowing strokes to soothe nerve endings in the skin, which will promote relaxation as well as pressure manipulation to ease surface tension. The aim of the Swedish Massage is to reduce muscular tension throughout the body by increasing the local circulation of blood and lymphatic fluids; to stretch muscle fibers and stimulate peripheral nervous system which in turn helps to encourage both physical and emotional wellbeing.
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="deepTissue" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>DEEP TISSUE MASSAGE</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>60 min - £85 / 90 min - £120</h5>
                <ListGroup.Item className="treatment-descriptions">
                  The Deep Tissue Massage is a form of massage that focuses on the deeper layers of muscle and connective tissue. It involves slow and deep strokes with more direct pressure or fraction applied across the grain of the muscles. A Deep Tissue Massage helps to free trapped nerves, boost blood and lymph circulation, reduce chronic pain, release tightness caused by physical and emotional stress.
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="bamboo" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>WARM BAMBOO MASSAGE</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>60 min - £95 / 90 min - £120</h5>
                <ListGroup.Item className="treatment-descriptions">
                  Originating in Asia and gaining popularity in the UK, this wonderful therapy is great to have if you like deep tissue work. It will leave you feeling relaxed and energised and with a sense that your muscles have been stretched, warmed and elongated.
                  <br />
                  <br />
                  A Warm Bamboo Massage uses solid bamboo sticks of different lengths and diameters to massage the body. The sticks are gently warmed and applied to give deep and long massage strokes to break down tension and stretch tight muscles.
                  <br />
                  <br />
                  In addition to managing stress, the bamboo massage improves lymphatic circulation promoting the removal of fluids and toxins, helps to break down and soften scarring adhesions and stimulate cellular activity to repair and nourish the skin. Lactic acid removal will reduce pain and muscle stiffness after sports activities.
                  <br />
                  <br />
                  It is also a great benefit to anyone suffering from shortened muscles due to postural imbalances.
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="thaiPregnancy" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>THAI PREGNANCY MASSAGE</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>60 min - £90 </h5>
                <ListGroup.Item className="treatment-descriptions">
                  The Thai Pregnancy Massage is mostly given in a comfortable side-lying position on the floor with the support of bolsters and cushions for the baby and mother to be. As pregnant women cannot lie on their fronts, kneeling over the beanbag is also a wonderful and safe position to receive back lines treatment which helps to release tension in the back. The beanbag also ensures that the tummy does not get squashed and the baby is fully protected.
                  <br />
                  <br />
                  The Thai Pregnancy Massage helps to soothe the aches, alleviate tension and most importantly calm the mind, which is the key to a relaxed pregnancy and a happy baby.
                  <br />
                  <br />
                  <Accordion flush className="treatment-readmores" defaultActiveKey="1">
                    <Card style={{ border: 'none' }}>
                      <Card.Header style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
                        <Accordion.Toggle style={{ textAlign: 'left', color: 'black', backgroundColor: 'white', padding: '0', fontStyle: 'italic' }}
                          eventKey="0">
                          READ MORE...
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '2vh 0 0' }}>
                          It also helps to:
                          <br />
                          <br />
                          <div style={{ paddingLeft: '35px' }}>
                            <TiTick />&nbsp;Decrease swelling in hands, feet, ankles and other areas that retain excess
                            fluid.
                            <br />
                            <TiTick />&nbsp;Lessen lower back and hip pain by easing pressure on the sciatic nerve and by relieving the tension in muscles of the back and neck.
                            <br />
                            <TiTick />&nbsp;Improve flexibility and muscle tone.
                            <br />
                            <TiTick />&nbsp;Relieve insomnia and improve sleep quality by promoting relaxation.
                            <br />
                            <TiTick />&nbsp;Improve circulation and oxygen in the blood; improved circulation can help with the elimination of toxins which can help alleviate fatigue, and help deliver more oxygen and nutrients to the growing baby.
                            <br />
                            <TiTick />&nbsp;Strengthen the immune system.
                            <br />
                            <TiTick />&nbsp;Stimulates the release of endorphins, the body’s natural painkillers.
                            <br />
                            <TiTick />&nbsp;Elevate stress and improve overall mood and decrease cortisol
                            production (which can be unhealthy when exposed to high levels for long periods).
                            <br />
                            <br />
                            Pregnancy puts a lot of stress on the body, and a post-pregnancy massage is a wonderful way to get your body back in its best shape quickly.
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="sculptingMassage" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>SCULPTING MASSAGE<br />
                  <i>Renata França Method</i></h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>60 min - £130 / 90 min - £170 / 3 x 60 min - £330 / 3 x 90 min - £408</h5>
                <ListGroup.Item className="treatment-descriptions">
                A manual massage technique that applies vigorous, fast and firm movements along the whole body. This massage presents surprising results, as it was conceived to model adipocytes, that is, moving the fat to the right places, thus giving the body a better contour. Kneading, pinching and gliding are some of the maneuvers that promise to give the body a new shape and ensure a curvy silhouette.
                  <br />
                  <br />
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

            </ListGroup>
          </Col>


          <Col sm={6} style={{ display: 'flex', justifyContent: 'center', padding: '0' }}>
            <ListGroup variant="flush">

              <div>
                <h6 id="lymphD" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>LYMPHATIC DRAINAGE<br/><i>Renata França Method</i></h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>60 min - £120 / 90 min - £160 / 3 x 60 min - £306 / 3 x 90 min - £384</h5>
                <ListGroup.Item className="treatment-descriptions">
                Firm pressure and accelerated rhythm,  pumping and exclusive maneuvers account for the method&apos;s success in bringing impressive results. This technique is designed to reduce edema, activate blood circulation and boost the complex network of vessels which transport fluids throughout the body, which contribute to reducing cellulite. As a result, the body may benefit from a reduction in swelling, better defined contour, accelerated metabolism and well-being sensation.
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="manualLymph" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>MANUAL LYMPHATIC DRAINAGE<br />
                  <i>Vodder Method</i></h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>60 min - £95 / 90 min - £120</h5>
                <ListGroup.Item className="treatment-descriptions">
                  Manual Lymphatic Drainage (MLD) is a gentle pressure type of massage that is used to drain excess fluid from body tissues by manipulating the flow of lymph fluid through the lymphatic system.
                  <br />
                  <br />
                  It involves very light pumping action made on the skin, rather than deep in the muscles. As well as helping to drain excess fluid, MLD is also used to reduce pain and aid relaxation.
                  Body fluids carry toxins, immune cells, and waste products to your lymph nodes. Lymph vessels help maintain fluid balance in the body by returning filtered lymph fluid back to the bloodstream. A poorly working or congested lymphatic system causes fluid and toxins to build up and tissues can become swollen with fluid.
                  <br />
                  <br />
                  <Accordion flush className="treatment-readmores" defaultActiveKey="1">
                    <Card style={{ border: 'none' }}>
                      <Card.Header style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
                        <Accordion.Toggle style={{ textAlign: 'left', color: 'black', backgroundColor: 'white', padding: '0', fontStyle: 'italic' }}
                          eventKey="0">
                          READ MORE...
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '2vh 0 0' }}>
                  MLD techniques facilitate the removal of metabolic wastes, excess water, toxins, bacteria, large protein molecules and foreign substances from tissues via the initial lymph vessels and venous capillaries. This type of treatment is very beneficial for people who regularly suffer from illnesses like cold and flu, migraines, sinus congestion, hay fever, skin disorders, rheumatoid arthritis, fibromyalgia, lipoedema and before and after cosmetic surgery to remove tissue congestion and minimize scar tissue.
                          <br />
                          <br />
                  Lymph drainage is part of a general detoxification program and is beneficial for anyone wishing to improve energy, increase cellular oxygenation and nourishment as well as build immunity.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="hotAndCold" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>HOT AND COLD STONE MASSAGE</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>60 min - £90 / 90 min - £130</h5>
                <ListGroup.Item className="treatment-descriptions">
                  The Hot Stone Massage is the treatment of choice for individuals who desire a natural form of chronic tension relief that acts on multiple levels – soothing the muscles, healing the body, relieving stress and facilitating exercise. Is it a delightfully relaxing treatment using warm basalt stones to massage the body from head to toe by increasing blood flow to the muscles. Increased blood flow delivers more oxygen, white blood cells, platelets, and essential nutrients – all of which help repair damaged tissues to heal. The heat from the stones soothes discomfort, elevates muscle stiffness and increases flexibility.
                  <br />
                  <br />
                  Cold Stones work by reducing blood flow which can significantly reduce inflammation and swelling, decrease muscle spasms and stimulate the autonomic nervous system.
                  <br />
                  <br />
                  <Accordion flush className="treatment-readmores" defaultActiveKey="1">
                    <Card style={{ border: 'none' }}>
                      <Card.Header style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
                        <Accordion.Toggle style={{ textAlign: 'left', color: 'black', backgroundColor: 'white', padding: '0', fontStyle: 'italic' }}
                          eventKey="0">
                          READ MORE...
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '2vh 0 0' }}>
                          A combination of hot and cold stones used in therapy creates a dramatic movement of fluids within the body generating healing, nourishing, toning and strengthening responses to several body systems, most notable the immune system. A Hot and Cold Stone Massage can also help to decongest, pushing blood, fluids and waste out of the overworked, tense or inflamed tissues. It can relieve lower back pain, muscle strains, and even help people suffering from arthritis.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="myofascial" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>MYOFASCIAL RELEASE</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>60 min - £85</h5>
                <ListGroup.Item className="treatment-descriptions">
                  Fascia is a sheet of connective tissue made of collagen and elastin that covers all the structures in the body and binds them together like a spider`s web. Tight myofascial tissues in the body can become restricted due to injury, overuse, inactivity and result in pain, tension and reduced blood flow. Myofascial Release is a specialized treatment focusing on soft tissue dysfunction that releases restriction within the fascial network (connective tissue), enabling stuck, hardened and dehydrated areas to become pliable, soft and mobile.  It is a treatment that involves gentle sustained pressure. Myofascial release helps people suffering from a wide range of health problems, from back to neck pain and scoliosis to sports injuries, chronic fatigue syndrome, rheumatoid arthritis.
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="lomi" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>LOMI LOMI</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>90 min - £180</h5>
                <ListGroup.Item className="treatment-descriptions">
                  Lomi Lomi is a form of traditional Hawaiian massage which is performed with flowing, rhythmical motion and has been described as feeling like gentle waves moving over the body. This massage technique works gently yet deeply into the muscles allowing the recipient to relax and give in to the nurturing touch.
                  <br />
                  <br />
                  The treatment may be slow and relaxing or faster and more invigorating, depending on how your body responds. You might find that the therapist massages two different parts of your body simultaneously - this is to stop your brain from focusing on any one area and therefore helps to aid relaxation. Lomi Lomi aims to treat the body and mind as one whole being.
                  <br />
                  <br />
                  Macadamia, palm or coconut oils are usually used as moisturisers in lomi lomi massage, to keep the strokes smooth and to nourish the skin.
                  <br />
                  <br />
                  <Accordion flush className="treatment-readmores" defaultActiveKey="1">
                    <Card style={{ border: 'none' }}>
                      <Card.Header style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
                        <Accordion.Toggle style={{ textAlign: 'left', color: 'black', backgroundColor: 'white', padding: '0', fontStyle: 'italic' }}
                          eventKey="0">
                          READ MORE...
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '2vh 0 0' }}>
                          Lomi Lomi massage therapy has proven to help:
                          <br />
                          <br />
                          <div style={{ paddingLeft: '35px' }}>
                            <TiTick />&nbsp;Improve circulation, strengthening the immune system.
                            <br />
                            <TiTick />&nbsp;Eliminate toxins from the body and tissues.
                            <br />
                            <TiTick />&nbsp;Release tension.
                            <br />
                            <TiTick />&nbsp;Increase range of movements and flexibility.
                            <br />
                            <TiTick />&nbsp;Slow the heart rate and lower blood pressure.
                            <br />
                            <TiTick />&nbsp;Remove lactic acid build-up.
                            <br />
                            <TiTick />&nbsp;Reduce headache and migraines.
                            <br />
                            <TiTick />&nbsp;Increasing metabolic rate.
                            <br />
                            <TiTick />&nbsp;Promote self-healing.
                            <br />
                            <TiTick />&nbsp;Restore wellbeing.
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="fireCupping" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>FIRE CUPPING</h6>
                <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>30 min - £40 </h5>
                <ListGroup.Item className="treatment-descriptions">
                Fire cupping is a type of alternative therapy that involves placing heated bell-shaped glass cups on the skin to create suction. When the cup is placed onto your skin, the air inside the cup cools and creates a vacuum that draws the skin and muscle up into the cup. It increases blood circulation to the area where the cups are placed. This may relieve muscle tension, which can improve overall blood flow and promote cell repair. It may also help form new connective tissues and create new blood vessels in the tissue.
                  <br />
                  <br />
                  This suction is thought to improve the flow of energy in the body and facilitate healing. The suction helps facilitate the flow of “qi” in the body. Qi is a Chinese word meaning &apos;life force&apos;.
                  <br />
                  <br />
                  <Accordion flush className="treatment-readmores" defaultActiveKey="1">
                    <Card style={{ border: 'none' }}>
                      <Card.Header style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
                        <Accordion.Toggle style={{ textAlign: 'left', color: 'black', backgroundColor: 'white', padding: '0', fontStyle: 'italic' }}
                          eventKey="0">
                          READ MORE...
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '2vh 0 0' }}>
                        Like acupuncture, cupping follows the lines of the meridians. There are five meridian lines on the back, and these are where the cups are usually placed.
                          <br />
                          <br />
                          Many believe that cupping helps balance yin and yang, or the negative and positive, within the body. Restoring balance between these two extremes is thought to help with the body&apos;s resistance to pathogens as well as its ability to increase blood flow and reduce pain.
                          <br />
                          <br />
                          Fire cupping therapy is used to relieve back and neck pains, stiff muscles, anxiety, fatigue, migraines, rheumatism, and even cellulite. It also helps to clear congestion from a common cold or help to control a person&apos;s asthma.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

              <div>
                <h6 id="kinesiology" style={{ marginTop: '3vh', color: 'black', padding: '2vh 20px 0', fontSize: '25px', fontWeight: 'bolder', textAlign: 'left' }}>KINESIOLOGY TAPING</h6>
                {/* <h5 style={{ marginTop: '2.5vh', color: 'black', padding: '0vh 20px', fontSize: '16px', textAlign: 'left', fontStyle: 'italic', fontWeight: 'bold' }}>30 min - £40 </h5> */}
                <ListGroup.Item className="treatment-descriptions">
                Kinesiology tape is commonly used for pain reduction, muscle and joint support, oedema and swelling. It is also used to optimise recovery after injuries and improve athletic performance. 
                  <br />
                  <br />
                  When the tape is applied to your body, it recoils slightly, gently lifting your skin, facia and underlying tissues. It is believed that this helps to create a microscopic space between your skin and the tissues underneath. This promotes better circulation and lymphatic drainage, reducing swelling and inflammation. Increased space within joints helps to reduce joint irritation.  
                  <br />
                  <br />
                  Many studies indicate that kinesiology taping is the most effective when used together with conventional treatment methods. 
                  <br />
                  <br />
                  <div style={{ textAlign: 'center', marginBottom: '0' }}>
                    <Button style={{ margin: '5vh' }} href="https://app.acuityscheduling.com/schedule.php?owner=19104690" className="btn-primary" >Book Now</Button>
                  </div>
                </ListGroup.Item>
              </div>

            </ListGroup>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  )
}

export default Treatments
