import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Image1 from './../assets/0R3A4316.jpg'
import Image2 from './../assets/0R3A4469.jpg'
import Image3 from './../assets/0R3A4803.jpg'
import Image4 from './../assets/0R3A4887.jpg'
import Image5 from './../assets/0R3A0276copy.jpg'
import Image6 from './../assets/0R3A0052.jpg'
import Image7 from './../assets/0R3A0231.jpg'
import Image8 from './../assets/0R3A0134.jpg'
import Image9 from './../assets/0R3A0125.jpg'
import Image10 from './../assets/0R3A0042.jpg'
import RightArrow from './../assets/next-svgrepo-com.svg'
import Cross from './../assets/x-svgrepo-com.svg'
import Image11 from './../assets/0R3A0304_resized.jpg'
import Image12 from './../assets/0R3A0256_resized.jpg'
import Image13 from './../assets/0R3A0224_resized_2.jpg'
import Image14 from './../assets/0R3A0073_resized.jpg'
import Image15 from './../assets/0R3A0082.jpg'
import Image16 from './../assets/purpleOne.jpg'
import Image17 from './../assets/purpleTwo.jpg'
import Image18 from './../assets/purpleThree.jpg'
import Image19 from './../assets/purpleFour.jpg'
import Image20 from './../assets/shoulder.jpg'


const Gallery = () => {
  
  const [active, setActive] = useState(false)
  const [index, setIndex] = useState(0)

  // useEffect(() => {
  //   active ? 
  //   // setInterval(()=>{
  //   //   setIndex(index + 1)
  //   // }, 1500)
  // : null
  // })
  
  const handleClick = (event) => {
    setIndex(Number(event.target.id) - 1)
    setActive(true)
    // setInterval(()=>{
    //   setIndex(index + 1)
    // }, 1500)
  }
  
  const handleNext = () => {
    if (index === 19) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }
  const handlePrevious = () => {
    if (index === 0) {
      setIndex(19)
    } else {
      setIndex(index - 1)
    }
  }

  const handleClose = () => {
    setActive(false)
  }

  return (
    <>
      <div style={{ width: '100vw', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {active ?
          <div>
            <div className='carouselContainer'>
              <img onClick={handlePrevious}
                className='leftArrow'
                src={RightArrow}/>
              <Carousel className='active carouselImageContainer'
                activeIndex={index} 
              >
                <Carousel.Item>
                  <img
                    className="pImg img1"
                    src={Image1}
                    alt="First slide"
                  />
                  {/* <Carousel.Caption>
                <h3>First slide label</h3>
                {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                  {/* </Carousel.Caption> */}
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img2"
                    src={Image2}
                    alt="Second slide"
                  />
                  {/* <Carousel.Caption>
                  <h3>Second slide label</h3>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                  {/* </Carousel.Caption> */}
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img3"
                    src={Image3}
                    alt="Third slide"
                  />
                  {/* <Carousel.Caption>
                  <h3>Third slide label</h3>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                  {/* </Carousel.Caption> */}
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img4"
                    src={Image4}
                    alt="Fourth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img5"
                    src={Image5}
                    alt="Fifth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="hImg img6"
                    src={Image6}
                    alt="Sixth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="hImg img7"
                    src={Image7}
                    alt="Seventh slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="hImg img8"
                    src={Image8}
                    alt="Eigth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img9"
                    src={Image9}
                    alt="Ninth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img10"
                    src={Image10}
                    alt="Tenth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="hImg img11"
                    src={Image11}
                    alt="Eleventh slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="hImg img12"
                    src={Image12}
                    alt="Twelfth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="hImg img13"
                    src={Image13}
                    alt="Thirteenth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img14"
                    src={Image14}
                    alt="Fourteenth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img15"
                    src={Image15}
                    alt="Fifteenth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img15"
                    src={Image16}
                    alt="Sixteenth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img15"
                    src={Image17}
                    alt="Seventeenth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img15"
                    src={Image18}
                    alt="Eighteenth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img15"
                    src={Image19}
                    alt="Nineteenth slide"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="pImg img15"
                    src={Image20}
                    alt="Twentieth slide"
                  />
                </Carousel.Item>
                {/* <Carousel.Item style={{ backgroundColor: 'black !important' }}>
        <img
          className="d-block w-100"
          src="holder.js/800x400?text=Third slide&bg=20232a"
          alt="Fourth slide"
        />
        <Carousel.Caption>
          <h3>Fourth slide label</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item> */}
              </Carousel>
              <img onClick={handleNext}
                className='rightArrow'
                src={RightArrow}/>
              <img onClick={handleClose}
                style={{ height: '3vh',  position: 'absolute', top: '13vh', right: '10vw' }}
                src={Cross}/>
            </div>
          </div>
          :
          <div className='tilesContainer'>
            <div className='tile' >
              <img
                className="tile1"
                src={Image1}
                alt="First tile"
                onClick={handleClick}
                id={1}
              />
            </div>
            <div className='tile' >
              <img
                className="tile2"
                src={Image2}
                alt="First tile"
                onClick={handleClick}
                id={2}
              />
            </div>
            <div className='tile' >
              <img
                className="tile3"
                src={Image3}
                alt="First tile"
                onClick={handleClick}
                id={3}
              />
            </div>
            <div className='tile' >
              <img
                className="tile4"
                src={Image4}
                alt="First tile"
                onClick={handleClick}
                id={4}
              />
            </div>
            <div className='tile' >
              <img
                className="tile5"
                src={Image5}
                alt="First tile"
                onClick={handleClick}
                id={5}
              />
            </div>
            <div className='tile' >
              <img
                className="tile6"
                src={Image6}
                alt="First tile"
                onClick={handleClick}
                id={6}
              />
            </div>
            <div className='tile' >
              <img
                className="tile7"
                src={Image7}
                alt="First tile"
                onClick={handleClick}
                id={7}
              />
            </div>
            <div className='tile' >
              <img
                className="tile8"
                src={Image8}
                alt="First tile"
                onClick={handleClick}
                id={8}
              />
            </div>
            <div className='tile' >
              <img
                className="tile1"
                src={Image9}
                alt="First tile"
                onClick={handleClick}
                id={9}
              />
            </div>
            <div className='tile' >
              <img
                className="tile1"
                src={Image10}
                alt="First tile"
                onClick={handleClick}
                id={10}
              />
            </div>
            <div className='tile' >
              <img
                className="tile1"
                src={Image11}
                alt="First tile"
                onClick={handleClick}
                id={11}
              />
            </div>
            <div className='tile' >
              <img
                className="tile1"
                src={Image12}
                alt="First tile"
                onClick={handleClick}
                id={12}
              />
            </div><div className='tile' >
              <img
                className="tile1"
                src={Image13}
                alt="First tile"
                onClick={handleClick}
                id={13}
              />
            </div><div className='tile' >
              <img
                className="tile14"
                src={Image14}
                alt="First tile"
                onClick={handleClick}
                id={14}
              />
            </div>
            <div className='tile' >
              <img
                className="tile15"
                src={Image15}
                alt="First tile"
                onClick={handleClick}
                id={15}
              />
            </div>
            <div className='tile' >
              <img
                className="tile16"
                src={Image16}
                alt="First tile"
                onClick={handleClick}
                id={16}
              />
            </div>
            <div className='tile' >
              <img
                className="tile16"
                src={Image17}
                alt="First tile"
                onClick={handleClick}
                id={17}
              />
            </div>
            <div className='tile' >
              <img
                className="tile15"
                src={Image18}
                alt="First tile"
                onClick={handleClick}
                id={18}
              />
            </div>
            <div className='tile' >
              <img
                className="tile16"
                src={Image19}
                alt="First tile"
                onClick={handleClick}
                id={19}
              />
            </div>
            <div className='tile' >
              <img
                className="tile15"
                src={Image20}
                alt="First tile"
                onClick={handleClick}
                id={20}
              />
            </div>
          </div> }
      </div>
    </>


  )
}

export default Gallery
