import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { RiPhoneLine } from 'react-icons/ri'
import { MdMailOutline } from 'react-icons/md'
import { GoLocation } from 'react-icons/go'
import { AiOutlineCopyright } from 'react-icons/ai'

// import Footer from './Footer'

const Location = () => {
  return (
    <>
      <Container fluid style={{ display: 'flex', justifyContent: 'center', width: '100vw' }}>
        <span id="location" className="offset"></span>
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
            <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100vw', position: 'relative' }}>
              <h6 style={{ margin: '0.25rem 0', fontSize: '4vh', textAlign: 'center', padding: '5vh 0 5vh' }}>Location</h6>
            </div>
            <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '3rem' }}></div>
            {/* <h3 style={{ marginTop: '7vh' }}>Location</h3> */}
            <h6>
              <GoLocation /> &nbsp; 11 Gloucester Road, South Kensington, SW7 4PP
              <br />
              <br />
            (at Pretty Nails Salon)
              <br />
              <br />
              {/* width="600" height="450" style="border:0;" loading="lazy" */}
              <iframe style={{ width: '40vw', height: '30vw', border: '0', margin: '0 auto' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.7794975518605!2d-0.18575688431238593!3d51.498913779633725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487605fb7df0c423%3A0x67ae4d4a2458c5d!2sPretty%20Nails!5e0!3m2!1sen!2suk!4v1635197774380!5m2!1sen!2suk"></iframe>
            </h6>
            <br />
            <br />
            <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
            <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100vw', position: 'relative' }}>
              <h6 style={{ margin: '0.25rem 0', fontSize: '4vh', textAlign: 'center', padding: '5vh 0 5vh' }}>Opening Hours</h6>
            </div>
            <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '3rem' }}></div>
            {/* <h3 style={{ marginTop: '7vh' }}>Opening Hours</h3> */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div style={{ textAlign: 'right' }}>
              Tuesday:&nbsp;&nbsp;&nbsp;
                <br />
              Friday:&nbsp;&nbsp;&nbsp;
                <br />
              Sunday:&nbsp;&nbsp;&nbsp;
              </div>
              <div style={{ textAlign: 'left' }}>
              10am - 7pm
                <br />
              10am - 7pm
                <br />
              11am - 6pm
              </div>
            </div>
            <br />
            <br />
            <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
            <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100vw', position: 'relative' }}>
              <h6 style={{ margin: '0.25rem 0', fontSize: '4vh', textAlign: 'center', padding: '5vh 0 5vh' }}>Contact Us</h6>
            </div>
            <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '3rem' }}></div>
            {/* <h3 style={{ marginTop: '7vh' }}>Contact</h3> */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div style={{ textAlign: 'right' }}>
                <RiPhoneLine />&nbsp;&nbsp;&nbsp;
                <br />
                <MdMailOutline />&nbsp;&nbsp;&nbsp;
              </div>
              <div style={{ textAlign: 'left' }}>
                +44 7506554494
                <br />
              laura.tytherapy@gmail.com
              </div>
            </div>
            <Row>
              <Col sm={5} style={{ textAlign: 'right', paddingRight: '3.5px', marginRight: '10px' }}>
                <br />
              </Col>
              <Col sm={4} style={{ textAlign: 'left', paddingLeft: '3.5px' }}>
                <br />
              </Col>
            </Row>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
      <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', height: '18vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiOutlineCopyright />&nbsp;
          <p style={{ marginBottom: 0 }}>Copyright Laura Ramuckiene 2024</p>
        </div>
        {/* <p>Made by Jahnvi Patel</p> */}
      </div>
    </>
  )
}

export default Location
