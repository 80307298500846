import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import AboutImage from '../assets/C6A142431214F49A.jpg'
import About2 from '../assets/About2.jpg'
import InsuranceLogo from '../assets/InsuranceLogo.jpg'
import { AiOutlineCopyright } from 'react-icons/ai'


const About = () => {

  return (
    <>
      <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', bottom: '0' }}></div>
      <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', width: '100vw' }}>
        <h6 style={{
          margin: '0.25rem 0 0.25rem', fontSize: '12vh', textAlign: 'center', padding: '5vh 0',
          fontFamily: 'Herr Von Muellerhoff',
          // fontFamily: 'Courgette, Cursive',
        }}>Hi, I&apos;m Laura</h6>
      </div>
      <div style={{ height: '0.5rem', borderTop: 'solid #66a1a8 0.5rem', top: '0', marginBottom: '1rem' }}></div>
      
      <Row style={{ minHeight: '60vh', width: '100vw', display: 'flex', alignItems: 'center', margin: '0', paddingTop: '3vh', justifyContent: 'center', color: 'white' }}>
        <Col sm={4}
          style={{ padding: '1vw 0vh' }}
        >
          <div className="about-image-container">
            <img src={AboutImage} className="about-image" />
          </div>
        </Col>
        <Col sm={7} style={{ padding: '0vw 5vh' }}>
          <div className="about-text-container">
            <div style={{ color: '#0C3751', fontSize: '18px' }}>
              I have a very passionate mindset towards health and wellness and how massage and deep relaxation can positively affect one’s physical and emotional wellbeing.
              I am a member of the Guild of Holistic Therapists and I hold an ITEC Level 3 Diploma in Massage, Anatomy, Physiology and Pathology alongside a Bachelor`s degree in Management and Business Administration.
              <br />
              <br />
              My passion for a healthy living started when I was 7 years old with a dream to become a ballerina. Being at ballet school and performing in Ballet and Opera house alongside TV shows at such a young age taught me the importance of motivation and self-discipline. I understood that being healthy is defined by being both physically and mentally fit. Being healthy means taking care of yourself and that starts with taking care of your body. It shaped who I am today.
              <br />
              <br />
            </div>
          </div>
        </Col>
        <Col sm={10} style={{ padding: '0vw 5vh' }}>
          <div className="about-text-container">
            <div style={{ color: '#0C3751', fontSize: '18px' }}>
              My Thai Yoga Massage journey began in 2016 after visiting the OM Yoga Show in London, where I was introduced to my new interest. I then completed a Thai Yoga Massage practitioner course with one of the most respected teachers of Thai Yoga Massage, Ralf Marzen, and later undertook further training with Kira Balaskas, Bob Haddad and Dave Hoyle.
              <br />
              <br />
              Couple of years before my Thai Yoga Massage therapist journey started, my husband was diagnosed with MS and I was looking for ways to be able to help him. I have always believed that our bodies are designed to heal and considering the fact that the most important function of this particular type of massage is to encourage the body’s natural healing process, it helped him immensely.  Thai Yoga Massage combined with a living a healthy, well-balanced lifestyle has encouraged me to explore the fundamentals of Ayurveda, Theta healing, Gut Health and the importance of Mindfulness. Over the past 5 years I have delved deeper and completed numerous courses in order to develop my skills even further and to be able to offer a wider range of massage techniques to my clients. 
              <br />
              <br />
              Helping people find ways to feel better in their lives is where I find my purpose. 
              <div className="qualificationsButton">
                <a style={{ color: 'rgb(12, 55, 81)' }} href='/qualifications'>MORE ABOUT MY QUALIFICATIONS</a>
              </div>
              <br />
              <div className="about-image-container">
                <img src={About2} className="about-image" />
              </div>
              <br />
              <br />
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ backgroundColor: '#0C3751', textAlign: 'center', color: 'whitesmoke', marginBottom: '0', height: '17vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <img src={InsuranceLogo} className="insuranceLogo" />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '1vh' }}>
          <AiOutlineCopyright />&nbsp;
          <p style={{ marginBottom: 0 }}>Copyright Laura Ramuckiene 2024</p>
        </div>
        {/* <p>Made by Jahnvi Patel</p> */}
      </div>
    </>
  )
}

export default About
